<template>
  <div>
    <Customer/>
    <Product/>
    <Promise/>
  </div>
</template>

<script>
import Customer from '../bottomComponents/Customer'
import Product from '../bottomComponents/Product'
import Promise from '../bottomComponents/Promise'

export default {
  components: {
    Customer, Product, Promise
  }
}
</script>
